import React, { FunctionComponent, useContext, useState, useEffect } from "react";
import styled from "styled-components";
import { Link, RouteComponentProps, withRouter } from "react-router-dom";
import { PRIMARY_COLOR, PRIMARY_FONT, BLACK_COLOR } from "../../utils/theme";
import { BurgerIcon } from "../Dumb";
import { Base10Logo } from "../../svg/base10Logo";
import { observer } from "mobx-react";
import { StoreContext, useQuery } from "../../models/reactUtils";
import * as theme from "../../utils/theme";
import { trackEvent, trackNavClicked } from "../../services/mixpanel";

const HeaderComponent: FunctionComponent<RouteComponentProps> = (props) => {
  const store = useContext(StoreContext);

  const [defaultState, setDefaultState] = useState(false);
  const [burgerNav, setBurgerNav] = useState(false);
  const [scrollValue, setScrollValue] = useState(0);
  const [dummy, setDummy] = useState(0);

  const {
    user,
    user: { role, hbcu_data_room_access, accessible_funds, id },
    is_authenticated,
    logout,
    fetchUser
  } = store;

  const { data: userData, setQuery: setQueryFetch,  } = useQuery((store) =>
    store.queryUser(
      { where: { id: id } },
      (qb) =>
        qb.id.accessible_funds
    ),
    {
      fetchPolicy: "network-only"
    }
  );

  useEffect(() => {
    console.log("user_id", id);
    const query = store.queryUser(
      { where: { id: id } },
      (qb) =>
        qb.id.accessible_funds
      , 
      {
        fetchPolicy: "network-only"
      }
    );
    //query.refetch();
    setQueryFetch(query);
    query.refetch().then((temp) => {
      console.log("refetch complete");
      console.log("temp", temp);
      setDummy(dummy + 1);
    });
  }, []);

  // useEffect(() => {
  //   const setupQuery = () => {
  //     const query = store.queryUser(
  //       { where: { id: id } },
  //       (qb) => qb.id.accessible_funds
  //     );
  //     setQueryFetch(query);
  //     query.refetch(); // Explicitly fetch data
  //   };
  
  //   if (id) {
  //     setupQuery();
  //   }
  // }, [id, store, setQueryFetch]); 

  console.log("userData", userData);

  useEffect(() => {
    window.addEventListener("scroll", handleScroll);

    props.history.listen((location) => {
      if (location.pathname !== props.location.pathname) {
        props.location.pathname = location.pathname;
        // forceUpdate();
      }
    });
    return () => {
      window.removeEventListener("scroll", handleScroll);
    };
  }, []);

  useEffect(() => {
    if (scrollValue < -50) {
      setToChangedState();
    } else {
      setToDefaultState();
    }
  }, [scrollValue]);

  const handleScroll = () => {
    setScrollValue(document.body.getBoundingClientRect().top);
  };

  const setToDefaultState = () => {
    setDefaultState(true);
  };

  const setToChangedState = () => {
    setDefaultState(false);
  };

  const showBurgerNav = () => {
    setBurgerNav(!burgerNav);
  };

  const handleLogout = () => {
    trackEvent("logout");
    logout();
  };

  const renderNavList = () => {
    switch (role) {
      case "HBCUDR":
        return (
          <NavList>
            <NavListItemActive>
              <StyledLink
                onClick={() => trackNavClicked("Advancement Initiative Data Room")}
                to="/advancement-initiative-data-room"
              >
                Advancement Initiative Data Room
              </StyledLink>
            </NavListItemActive>
            <NavListItem>
              <StyledLinkExternal
                onClick={() => trackNavClicked("Model Portfolio Construction")}
                target="_blank"
                href="https://base10.vc/research-advancement-initiative?aidr=true"
              >
                Model Portfolio Construction
              </StyledLinkExternal>
            </NavListItem>
            <NavListItem>
              <StyledLinkExternal
                onClick={() => trackNavClicked("Visual Overview of the AI")}
                target="_blank"
                href="https://base10.vc/advancement-initiative/"
              >
                Visual Overview of the AI
              </StyledLinkExternal>
            </NavListItem>
            <NavListItem>
              <StyledText onClick={handleLogout}>Logout</StyledText>
            </NavListItem>
          </NavList>
        );
      default:
        return (
          <NavList>
            <NavListItem>
              <StyledLink onClick={() => trackNavClicked("Dashboard")} to="/investor-dashboard">
                Dashboard
              </StyledLink>
            </NavListItem>
            {/* {role !== "HBCU" && (
              <NavListItem>
                <StyledLink to="/data-room">Fund III Data Room</StyledLink>
              </NavListItem>
            )} */}

            <NavListItem>
              <StyledLink onClick={() => trackNavClicked("2023 AGM Materials")} to={`/data-room/20`}>
              2023 AGM Materials
              </StyledLink>
            </NavListItem>
            {userData?.user?.accessible_funds?.includes(21) && (
              <NavListItem>
                <StyledLink onClick={() => trackNavClicked("2024 Data Room")} to={`/data-room/21`}>
                  2024 Data Room
                </StyledLink>
              </NavListItem>
            )}
            {/* {(!!hbcu_data_room_access || role === "HBCU") && (
              <NavListItem>
                <StyledLink to="/advancement-initiative-data-room">Advancement Initiative Data Room</StyledLink>
              </NavListItem>
            )} */}
            <NavListItem>
              <StyledLink onClick={() => trackNavClicked("Update Letters")} to="/quarterly-update">
                Update Letters
              </StyledLink>
            </NavListItem>
            {role === "ADMIN" && (
              <NavListItem>
                <StyledLink onClick={() => trackNavClicked("Edit Info")} to="/editinfo">
                  Edit Info
                </StyledLink>
              </NavListItem>
            )}
            <NavListItem>
              <StyledText onClick={handleLogout}>Logout</StyledText>
            </NavListItem>
          </NavList>
        );
    }
  };
  const renderSideNav = () => {
    switch (role) {
      case "HBCUDR":
        return (
          <SideNavDiv showNav={burgerNav}>
            <NavigationContainer>
              <StyledLink
                to="/advancement-initiative-data-room"
                onClick={() => {
                  showBurgerNav();
                  trackNavClicked("Advancement Initiative Data Room");
                }}
              >
                Advancement Initiative Data Room
              </StyledLink>
              <StyledLinkExternal
                onClick={() => trackNavClicked("Model Portfolio Construction")}
                target="_blank"
                href="https://base10.vc/research-advancement-initiative?aidr=true"
              >
                Model Portfolio Construction
              </StyledLinkExternal>
              <StyledLinkExternal
                onClick={() => trackNavClicked("Visual Overview of the AI")}
                target="_blank"
                href="https://base10.vc/advancement-initiative/"
              >
                Visual Overview of the AI
              </StyledLinkExternal>
              <StyledText onClick={handleLogout}>Logout</StyledText>
            </NavigationContainer>
          </SideNavDiv>
        );
      default:
        return (
          <SideNavDiv showNav={burgerNav}>
            <NavigationContainer>
              <StyledLink
                onClick={() => {
                  showBurgerNav();
                  trackNavClicked("Dashboard");
                }}
                to="/investor-dashboard"
              >
                Dashboard
              </StyledLink>
              {/* <StyledLink to="/data-room" onClick={showBurgerNav}>
                Fund III Data Room
              </StyledLink> */}
              {/* {!!hbcu_data_room_access && (
                <StyledLink to="/advancement-initiative-data-room" onClick={showBurgerNav}>
                  Advancement Initiative Data Room
                </StyledLink>
              )} */}
              <StyledLink
                onClick={() => {
                  showBurgerNav();
                  trackNavClicked("Update Letters");
                }}
                to="/quarterly-update"
              >
                Update Letters
              </StyledLink>
              {role === "ADMIN" && (
                <StyledLink
                  onClick={() => {
                    trackNavClicked("Edit Info");
                    showBurgerNav();
                  }}
                  to="/editinfo"
                >
                  Edit Info
                </StyledLink>
              )}
              <StyledText onClick={handleLogout}>Logout</StyledText>
            </NavigationContainer>
          </SideNavDiv>
        );
    }
  };

  if (!is_authenticated) {
    return null;
  }
  return (
    <HeaderContainer solidBlack={true} defaultState={defaultState}>
      <HeaderWrapper>
        <HeaderIconContainer>
          <a href="https://base10.vc">
            <Base10Logo />
          </a>
        </HeaderIconContainer>
        <MobileBurger>
          <BurgerIcon onClick={showBurgerNav} showX={burgerNav} />
        </MobileBurger>
        <MobileIcon onClick={showBurgerNav}>
          <Base10Logo color={"#fff"} />
        </MobileIcon>
        {renderSideNav()}
        <HeaderOptions>{renderNavList()}</HeaderOptions>
      </HeaderWrapper>
    </HeaderContainer>
  );
};

interface ContainerState {
  defaultState: boolean;
  solidBlack: boolean;
}

const HeaderContainer = styled.div<ContainerState>`
  position: fixed;
  display: flex;
  flex-direction: row;
  justify-content: center;
  align-items: center;
  top: 0;
  left: 0;
  right: 0;
  z-index: 8;
  height: ${(props) => (props.defaultState ? "120px" : "60px")};
  background-color: ${(props) => (props.defaultState && !props.solidBlack ? "transparent" : BLACK_COLOR)};
  transition: background-color 500ms, height 500ms, padding-top 0.25s, padding-bottom 0.25s, top 0.25s;
`;

const NavListItemActive = styled.div`
  /* in case of more overflow */
  overflow: hidden;
  white-space: nowrap;
  text-overflow: ellipsis;
  margin-left: 25px;
  position: relative;
  overflow: visible;
  color: ${theme.WHITE_COLOR};
  &:before {
    content: ".";
    font-size: 30px;
    display: inline-block;
    position: absolute;
    bottom: -16px;
    left: 0;
    text-align: center;
    width: 100%;
    color: ${theme.PRIMARY_COLOR};
  }
`;

const HeaderWrapper = styled.div`
  width: 100%;
  position: relative;
  max-width: 1600px;
`;

const HeaderIconContainer = styled.div`
  width: calc(25% - 2.34375rem);
  margin-left: 1.875rem;
  float: left;
  @media ${"screen and (max-width: 768px)"} {
    display: none;
  }
`;

const MobileIcon = styled.div`
  display: none;
  @media ${"screen and (max-width: 768px)"} {
    position: relative;
    display: flex;
    justify-content: center;
    z-index: 9;
    /* width: calc(66.66667% - 2.08333rem); */
    width: 100%;
    /* float: left; */
    /* margin-left: 1.25rem; */
    text-align: center;
  }
`;

const MobileBurger = styled.div`
  display: none;
  @media ${"screen and (max-width: 768px)"} {
    display: block;
    position: absolute;
    left: 0.625rem;
    z-index: 9;
    height: 2.5rem;
    width: 2.5rem;
  }
`;

const HeaderOptions = styled.div`
  width: calc(75% - 3.28125rem);
  float: left;
  margin-left: 1.875rem;
  margin-top: 0.125rem;
  text-align: right;
`;

const NavList = styled.div`
  display: flex;
  justify-content: flex-end;
  margin-top: 0;
  margin-bottom: 0;
  padding-left: 0;
  @media ${"screen and (max-width: 768px)"} {
    display: none;
  }
`;

const NavListItem = styled.div`
  margin-left: 25px;
`;

const StyledLink = styled(Link)`
  font-family: ${PRIMARY_FONT};
  font-weight: 300;
  -webkit-font-smoothing: antialiased;
  transition: color 0.25s, opacity 0.25s;
  font-size: 18px;
  line-height: 1.44444;
  letter-spacing: 0;
  color: #fff;
  text-decoration: none;
  text-align: center;
  :hover {
    color: ${PRIMARY_COLOR};
    text-decoration: none;
  }
`;

const StyledLinkExternal = styled.a`
  font-family: ${PRIMARY_FONT};
  font-weight: 300;
  -webkit-font-smoothing: antialiased;
  transition: color 0.25s, opacity 0.25s;
  font-size: 18px;
  line-height: 1.44444;
  letter-spacing: 0;
  color: #fff;
  text-decoration: none;
  text-align: center;
  :hover {
    color: ${PRIMARY_COLOR};
    text-decoration: none;
  }
`;

const StyledText = styled.p`
  font-family: ${PRIMARY_FONT};
  font-weight: 300;
  -webkit-font-smoothing: antialiased;
  transition: color 0.25s, opacity 0.25s;
  font-size: 18px;
  line-height: 1.44444;
  letter-spacing: 0;
  color: #fff;
  text-decoration: none;
  margin: 0;
  :hover {
    color: ${PRIMARY_COLOR};
    text-decoration: none;
    cursor: pointer;
  }
`;

interface SideNavProps {
  showNav: boolean;
}

const SideNavDiv = styled.div<SideNavProps>`
  display: none;
  @media ${"screen and (max-width: 768px)"} {
    display: ${(props) => (props.showNav ? "flex" : "none")};
    height: 100vh;
    width: 101%;
    opacity: ${(props) => (props.showNav ? 1 : 0)};
    position: absolute;
    z-index: ${(props) => (props.showNav ? 7 : -1)};
    left: -1px;
    top: -1px;
    overflow-x: hidden;
    transition: 200ms;
    background-color: ${BLACK_COLOR};
  }
`;

const NavigationContainer = styled.div`
  /* margin-top: 100px; */
  width: 100%;
  height: 100%;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
`;

const Header = observer(withRouter(HeaderComponent as any));

export { Header };
