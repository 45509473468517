import * as React from "react";
import styled from "styled-components";
import { BLACK_COLOR, PRIMARY_FONT, PRIMARY_COLOR, WHITE_COLOR } from "../../utils/theme";
import { AppSelect, AppText, AppPanelTitle } from "../UI";
import { FunctionComponent, useEffect, useState } from "react";
import { smallMobileSize, mobileSize, laptopSize } from "../../utils/breakpoints";
import {
  CapitalDeployedComponent,
  StatsComponent,
  CapitalDeployedByIndustryComponent,
  PortfolioComponent,
  ResearchProcessPipeComponent,
  CapitalDeployedByStateComponent,
  FMVContributinosByCompanyComponent,
  CapitalDeployedByQuarterComponent,
} from "../Segments/DashboardPanels";
import { useQuery } from "../../models/reactUtils";
import { observer } from "mobx-react";
import ReactGA from "react-ga";
import { DRDisclaimerPopupComponent } from "../Modals";
import { returnIfNormalFund } from "../Dumb/utils";
import { PanelContainer, PanelWrapper } from "../Dumb/";
import { initMixpanel, trackFundChanged } from "../../services/mixpanel";
import { FootersAndDisclosures } from "../Segments/DashboardPanels/FootersAndDisclosures";
import { Box } from "@material-ui/core";

interface InvestorDashboardProps {
  capital_deployed_perc_deployed_and_reserved: string;
  capital_deployed_perc_unallocated: string;
}

const InvestorDashboardComponent: FunctionComponent = () => {
  const {
    store,
    store: { user, fund_list, selectFund, selected_fund, modalShown, showDisclaimerModal },
  } = useQuery();
  const [showModal, setShowModal] = useState(false);

  const advancementInitiativeOneIssSelected = selected_fund?.id === 14;

  const accessibleFunds = () => {
    return fund_list.filter((fund: any) => user.accessible_funds.includes(Number(fund.value)));
  };

  console.log("fund_list", fund_list);

  // Runs on mount
  useEffect(() => {
    initMixpanel(user);
    if (showDisclaimerModal) {
      setShowModal(true);
    }
  }, []);

  const handleSelectFund = (event: any) => {
    event.preventDefault();
    const fundLabel = fund_list.find((obj) => obj.value === event.target.value).label;
    selectFund(event.target.value);
    trackFundChanged(fundLabel);
    // changeSelectedFund(event.target.value);
  };

  useEffect(() => {
    ReactGA.set({ email: store.user.email, id: store.user.id, site: "portal", username: store.user.username });
    ReactGA.pageview(window.location.pathname + window.location.search, [selected_fund.name]);
    ReactGA.event({ action: `Dashboard: ${selected_fund.name}`, category: `Dashboard View` });
  }, [store && store.user && store.user.id]);

  const isAdvancementInitiativeFundII = selected_fund.id === 16;

  return (
    <DashboardContainer>
      {showModal && <DRDisclaimerPopupComponent showModal={showModal} toggleModal={() => setShowModal(false)} />}
      <LeftSection>
        {fund_list.length > 1 ? (
          <SelectContainer>
            <AppSelect
              // options={[...fund_list.slice(), { value: 14, label: "AI 1" }]}
              options={accessibleFunds()
                .map((item) => item)
                .sort((a: any, b: any) => (`${a.label}` > `${b.label}` ? 1 : -1))
                .map((item) => ({
                  ...item,
                  label: Number(item.value) === 16 ? "Series B Fund II" : item.label,
                }))}
              value={selected_fund.id}
              onChange={handleSelectFund}
              width={415}
              fontSize={36}
              color="#fff"
              ignorePlaceholderOpacity
              noUnderline
            />
          </SelectContainer>
        ) : (
          <FundText>{isAdvancementInitiativeFundII ? "Series B Fund II" : selected_fund.name}</FundText>
        )}
      </LeftSection>
      <InnerDashboardContainer>
        <Box
          style={{
            display: "flex",
            justifyContent: "space-between",
            width: "100%",
            alignItems: "center",
            marginTop: "30px",
            padding: "0px 30px",
          }}
        >
          <CapitalDeployedComponent fund_id={selected_fund.id} />
          <StatsComponent fund_id={selected_fund.id} />
        </Box>
        {/* {!returnIfNormalFund(selected_fund.id) && (
          <MiddleOfContainerDiv>
            <AdvancementInitiative1Chart1 />
          </MiddleOfContainerDiv>
        )} */}
        {!advancementInitiativeOneIssSelected && (
          <Box
            style={{
              display: "flex",
              justifyContent: "space-between",
              width: "100%",
              alignItems: "center",
              marginTop: "30px",
              gap: "30px",
              padding: "0px 30px",
            }}
          >
            <CapitalDeployedByStateComponent fund_id={selected_fund.id} />
            <FMVContributinosByCompanyComponent
              total_investment_value={selected_fund.total_investment_value}
              total_company_value={selected_fund.total_company_value}
              fund_id={selected_fund.id}
            />
            {/* <CapitalDeployedByIndustryComponent fund_id={selected_fund.id} /> */}
          </Box>
        )}
        <PortfolioComponent fund_id={selected_fund.id} />
        {returnIfNormalFund(selected_fund.id) ? (
          <>
            <Box
              style={{
                display: "flex",
                justifyContent: !advancementInitiativeOneIssSelected ? "center" : "space-between",
                width: "100%",
                alignItems: "center",
                marginTop: "30px",
                gap: "30px",
                padding: "0px 30px",
              }}
            >
              {!advancementInitiativeOneIssSelected && (
                <CapitalDeployedByQuarterComponent fund_id={selected_fund.id} halfWidth={true} />
              )}
              {advancementInitiativeOneIssSelected && (
                <FMVContributinosByCompanyComponent
                  total_investment_value={selected_fund.total_investment_value}
                  total_company_value={selected_fund.total_company_value}
                  fund_id={selected_fund.id}
                />
              )}
            </Box>
          </>
        ) : (
          <>
            {/* {!advancementInitiativeOneIssSelected && (
              <MiddleOfContainerDiv>
                <FMVContributinosByCompanyComponent
                  total_investment_value={selected_fund.total_investment_value}
                  total_company_value={selected_fund.total_company_value}
                  fund_id={selected_fund.id}
                />
              </MiddleOfContainerDiv>
            )} */}
          </>
        )}
        {/* <ResearchProcessPipeComponent fund_id={selected_fund.id} /> */}
        {!returnIfNormalFund(selected_fund.id) && <FootersAndDisclosures fund_id={selected_fund.id} />}
      </InnerDashboardContainer>
    </DashboardContainer>
  );
};

const FooterText = styled(AppText)`
  font-size: 11px;
  opacity: 0.6;
  margin-top: 30px;
`;

const MiddleOfContainerDiv = styled.div`
  width: 100%;
  /* margin: 0px auto; */
  /* background-color: ${WHITE_COLOR}; */
  display: flex;
    /* width: calc(100% - 60px); */
  justify-content: center;
  padding: 0px;
  margin: 0px;
  float: left;
  @media ${laptopSize} {
    justify-content: flex-start;
    /* width: calc(100% - 60px); */
  }
  @media ${mobileSize} {
    /* width: calc(100% - 60px); */
  }

`;

const DashboardContainer = styled.div`
  position: relative;
  padding-bottom: 60px;
  background-color: rgba(26, 26, 26, 0.1);
  ::before {
    content: "";
    position: absolute;
    top: 0;
    left: 0;
    z-index: -1;
    height: 245px;
    width: 100%;
    background-color: ${BLACK_COLOR};
  }
  @media ${mobileSize} {
    margin-bottom: 20px;
    padding-bottom: 40px;
  }
  @media ${smallMobileSize} {
    ::before {
      height: 275px;
    }
  }
`;

const InnerDashboardContainer = styled.div`
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-orient: horizontal;
  -webkit-box-direction: normal;
  -ms-flex-direction: row;
  flex-direction: row;
  -ms-flex-wrap: wrap;
  flex-wrap: wrap;
  position: relative;
  z-index: 1;
  max-width: 1600px;
  margin-right: auto;
  margin-left: auto;
  /* top: -100px; */
  @media ${mobileSize} {
    top: 0px;
    max-width: 100%;
    margin-bottom: -48px;
  }
`;

const LeftSection = styled.div`
  display: flex;
  flex-direction: row;
  padding-top: 170px;
  z-index: 1;
  margin-right: auto;
  margin-left: auto;
  max-width: 1600px;
  @media ${smallMobileSize} {
    flex-direction: column;
  }
`;

const FundText = styled.div`
  height: 50px;
  width: auto;
  font-family: ${PRIMARY_FONT};
  font-size: 36px;
  font-weight: normal;
  font-stretch: normal;
  font-style: normal;
  line-height: 1.39;
  letter-spacing: -0.8px;
  color: #ffffff;
  margin-left: 30px;
  @media ${mobileSize} {
    flex-direction: column;
    margin-left: 20px;
  }
`;

const SelectContainer = styled.div`
  margin-left: 15px;
`;

const InvestorDashboard = observer(InvestorDashboardComponent);

export { InvestorDashboard };
