import * as React from "react";
import styled from "styled-components";
import { AppPanelTitle, AppText, Loading } from "../../UI";
import { HalfPanelContainer, PanelContent, PanelWrapper } from "../../Dumb";
import { FunctionComponent, useEffect } from "react";
import { BLACK_COLOR, PRIMARY_COLOR } from "../../../utils/theme";
import { observer } from "mobx-react";
import { useQuery } from "../../../models/reactUtils";
import { returnIfNormalFund } from "../../Dumb/utils";

interface CapitalDeployedByStateProps {
  fund_id: number;
}

const CapitalDeployedByStatePanel: FunctionComponent<CapitalDeployedByStateProps> = (props) => {
  const { store } = useQuery();

  const { data: fundData, loading: fundLoading, error: fundError, setQuery: fundQuery } = useQuery((store) =>
    store.queryFund(
      { where: { id: props.fund_id } },
      (qb) =>
        qb.seed_primary.seed_follow_on.series_a_primary.series_a_primary.series_a_follow_on.series_b_primary
          .series_b_follow_on.reserved_but_not_deployed.unallocated.total_investable_capital.graph_early_growth
          .graph_growth.graph_late_growth.capital_deployed_by_stage_footnote,
    ),
  );

  useEffect(() => {
    const query = store.queryFund(
      { where: { id: props.fund_id } },
      (qb) =>
        qb.seed_primary.seed_follow_on.series_a_primary.series_a_primary.series_a_follow_on.series_b_primary
          .series_b_follow_on.reserved_but_not_deployed.unallocated.total_investable_capital.graph_early_growth
          .graph_growth.graph_late_growth.capital_deployed_by_stage_footnote,
    );
    fundQuery(query);
  }, [props.fund_id]);

  if (fundLoading) {
    return (
      <HalfPanelContainer>
        <PanelWrapper>
          <Loading />
        </PanelWrapper>
      </HalfPanelContainer>
    );
  }

  if (fundError || !fundData || !fundData.fund) {
    return <p>Error</p>;
  }

  const {
    fund: {
      seed_primary,
      seed_follow_on,
      series_a_primary,
      series_a_follow_on,
      series_b_primary,
      series_b_follow_on,
      reserved_but_not_deployed,
      unallocated,
      total_investable_capital,
      graph_early_growth,
      graph_growth,
      graph_late_growth,
      capital_deployed_by_stage_footnote,
    },
  } = fundData;

  // let graph_early_growth = 23;
  // let graph_growth = 28;
  // let graph_late_growth = 19;

  const totalAmount =
    returnIfNormalFund(props.fund_id) || props.fund_id === 14
      ? seed_primary +
        seed_follow_on +
        series_a_primary +
        series_a_follow_on +
        series_b_primary +
        series_b_follow_on +
        reserved_but_not_deployed +
        // graph_early_growth +
        // graph_growth +
        // graph_late_growth +
        10 // Using this 10 to adjust the total width so that the bars don't overflow into the next line
      : graph_early_growth + graph_growth + graph_late_growth + 10;

  return (
    <HalfPanelContainer>
      <PanelWrapper>
        <>
          <AppPanelTitle>CAPITAL DEPLOYED BY STAGE ($M)</AppPanelTitle>
          <PanelContent>
            <ChartContainer>
              {returnIfNormalFund(props.fund_id) ? (
                <LegendContainer>
                  <LegendItem>
                    <InnerLegendItem>
                      <LegendColorItem>
                        <LegendColor color={BLACK_COLOR} />
                      </LegendColorItem>
                      <LegendLabel>
                        <LegendText>Primary</LegendText>
                      </LegendLabel>
                    </InnerLegendItem>
                  </LegendItem>
                  <LegendItem>
                    <InnerLegendItem>
                      <LegendColorItem>
                        <LegendColor color={PRIMARY_COLOR} />
                      </LegendColorItem>
                      <LegendLabel>
                        <LegendText>Follow-on</LegendText>
                      </LegendLabel>
                    </InnerLegendItem>
                  </LegendItem>
                </LegendContainer>
              ) : (
                <div style={{ height: "20px" }} />
              )}
              {returnIfNormalFund(props.fund_id) || props.fund_id === 14 ? (
                <>
                  <StyledChart>
                    {seed_primary + seed_follow_on !== 0 && (
                      <ChartListItem>
                        <ChartItemLabel>
                          <ChartText>Seed</ChartText>
                        </ChartItemLabel>
                        <ChartItemContent>
                          <ChartStackedBar>
                            {seed_primary !== 0 && (
                              <MainBar width={seed_primary / totalAmount}>
                                <BarItem color={BLACK_COLOR}>
                                  <BarValue>
                                    <BarValueText color="#fff">${seed_primary}</BarValueText>
                                  </BarValue>
                                </BarItem>
                              </MainBar>
                            )}
                            {seed_follow_on !== 0 && (
                              <MainBar width={seed_follow_on / totalAmount}>
                                <BarItem color={PRIMARY_COLOR}>
                                  <BarValue>
                                    <BarValueText color="#fff">${seed_follow_on}</BarValueText>
                                  </BarValue>
                                </BarItem>
                              </MainBar>
                            )}
                          </ChartStackedBar>
                        </ChartItemContent>
                      </ChartListItem>
                    )}
                    {series_a_primary + series_a_follow_on !== 0 && (
                      <ChartListItem>
                        <ChartItemLabel>
                          <ChartText>Series A</ChartText>
                        </ChartItemLabel>
                        <ChartItemContent>
                          <ChartStackedBar>
                            <MainBar width={(seed_primary + seed_follow_on) / totalAmount}>
                              <BarItem color="transparent"></BarItem>
                            </MainBar>
                            {series_a_primary !== 0 && (
                              <MainBar width={series_a_primary / totalAmount}>
                                <BarItem color={BLACK_COLOR}>
                                  <BarValue>
                                    <BarValueText color="#fff">${series_a_primary}</BarValueText>
                                  </BarValue>
                                </BarItem>
                              </MainBar>
                            )}
                            {series_a_follow_on !== 0 && (
                              <MainBar width={series_a_follow_on / totalAmount}>
                                <BarItem color={PRIMARY_COLOR}>
                                  <BarValue>
                                    <BarValueText color="#fff">${series_a_follow_on}</BarValueText>
                                  </BarValue>
                                </BarItem>
                              </MainBar>
                            )}
                          </ChartStackedBar>
                        </ChartItemContent>
                      </ChartListItem>
                    )}
                    {series_b_follow_on + series_b_primary !== 0 && (
                      <ChartListItem>
                        <ChartItemLabel>
                          <ChartText>Series B</ChartText>
                        </ChartItemLabel>
                        <ChartItemContent>
                          <ChartStackedBar>
                            <MainBar
                              width={
                                (seed_primary + seed_follow_on + series_a_follow_on + series_a_primary) / totalAmount
                              }
                            >
                              <BarItem color="transparent"></BarItem>
                            </MainBar>
                            {series_b_primary !== 0 && (
                              <MainBar width={series_b_primary / totalAmount}>
                                <BarItem color={BLACK_COLOR}>
                                  <BarValue>
                                    <BarValueText color="#fff">${series_b_primary}</BarValueText>
                                  </BarValue>
                                </BarItem>
                              </MainBar>
                            )}
                            {series_b_follow_on !== 0 && (
                              <MainBar width={series_b_follow_on / totalAmount}>
                                <BarItem color={PRIMARY_COLOR}>
                                  <BarValue>
                                    <BarValueText color="#fff">${series_b_follow_on}</BarValueText>
                                  </BarValue>
                                </BarItem>
                              </MainBar>
                            )}
                          </ChartStackedBar>
                        </ChartItemContent>
                      </ChartListItem>
                    )}
                    {reserved_but_not_deployed !== 0 && (
                      <ChartListItem>
                        <ChartItemLabel>
                          <ChartText>Reserved but not deployed</ChartText>
                        </ChartItemLabel>
                        <ChartItemContent>
                          <ChartStackedBar>
                            <MainBar
                              width={
                                (seed_primary +
                                  seed_follow_on +
                                  series_a_follow_on +
                                  series_a_primary +
                                  series_b_follow_on +
                                  series_b_primary) /
                                totalAmount
                              }
                            >
                              <BarItem color="transparent"></BarItem>
                            </MainBar>
                            <MainBar width={reserved_but_not_deployed / totalAmount}>
                              <BarItem>
                                <BarValue>
                                  <BarValueText color={BLACK_COLOR}>${reserved_but_not_deployed}</BarValueText>
                                </BarValue>
                              </BarItem>
                            </MainBar>
                          </ChartStackedBar>
                        </ChartItemContent>
                      </ChartListItem>
                    )}
                    {unallocated !== 0 && (
                      <ChartListItem>
                        <ChartItemLabel>
                          <ChartText>Unallocated</ChartText>
                        </ChartItemLabel>
                        <ChartItemContent>
                          <ChartStackedBar>
                            <UnallocatedContainer>
                              <InnerUnallocatedContainer>
                                <UnallocatedContainerContent>
                                  <UnallocatedContainerInnerContent>
                                    <CircleValueText color="#fff">${unallocated}</CircleValueText>
                                  </UnallocatedContainerInnerContent>
                                </UnallocatedContainerContent>
                              </InnerUnallocatedContainer>
                            </UnallocatedContainer>
                          </ChartStackedBar>
                        </ChartItemContent>
                      </ChartListItem>
                    )}
                  </StyledChart>
                  {capital_deployed_by_stage_footnote && <FooterText>{capital_deployed_by_stage_footnote}</FooterText>}
                </>
              ) : (
                <>
                  <StyledChart>
                    {graph_early_growth !== 0 && (
                      <ChartListItem>
                        <ChartItemLabel>
                          <ChartText>Early-Growth</ChartText>
                        </ChartItemLabel>
                        <ChartItemContent>
                          <ChartStackedBar>
                            {/* <MainBar width={graph_early_growth / totalAmount}>
                            <BarItem color="transparent"></BarItem>
                          </MainBar> */}
                            {graph_early_growth !== 0 && (
                              <MainBar width={graph_early_growth / totalAmount}>
                                <BarItem color={PRIMARY_COLOR}>
                                  <BarValue>
                                    <BarValueText color="#fff">${graph_early_growth}</BarValueText>
                                  </BarValue>
                                </BarItem>
                              </MainBar>
                            )}
                          </ChartStackedBar>
                        </ChartItemContent>
                      </ChartListItem>
                    )}
                    {graph_growth !== 0 && (
                      <ChartListItem>
                        <ChartItemLabel>
                          <ChartText>Growth</ChartText>
                        </ChartItemLabel>
                        <ChartItemContent>
                          <ChartStackedBar>
                            <MainBar width={graph_early_growth / totalAmount}>
                              <BarItem color="transparent"></BarItem>
                            </MainBar>
                            {graph_growth !== 0 && (
                              <MainBar width={graph_growth / totalAmount}>
                                <BarItem color={PRIMARY_COLOR}>
                                  <BarValue>
                                    <BarValueText color="#fff">${graph_growth}</BarValueText>
                                  </BarValue>
                                </BarItem>
                              </MainBar>
                            )}
                          </ChartStackedBar>
                        </ChartItemContent>
                      </ChartListItem>
                    )}
                    {graph_late_growth !== 0 && (
                      <ChartListItem>
                        <ChartItemLabel>
                          <ChartText>Late-Growth</ChartText>
                        </ChartItemLabel>
                        <ChartItemContent>
                          <ChartStackedBar>
                            <MainBar width={(graph_early_growth + graph_growth) / totalAmount}>
                              <BarItem color="transparent"></BarItem>
                            </MainBar>
                            {graph_late_growth !== 0 && (
                              <MainBar width={graph_late_growth / totalAmount}>
                                <BarItem color={PRIMARY_COLOR}>
                                  <BarValue>
                                    <BarValueText color="#fff">${graph_late_growth}</BarValueText>
                                  </BarValue>
                                </BarItem>
                              </MainBar>
                            )}
                          </ChartStackedBar>
                        </ChartItemContent>
                      </ChartListItem>
                    )}
                  </StyledChart>
                  {capital_deployed_by_stage_footnote && <FooterText>{capital_deployed_by_stage_footnote}</FooterText>}
                </>
              )}
            </ChartContainer>
            {/* <TotalContainer>
            <ChartBoldText>${total_investable_capital}</ChartBoldText>
            <ChartText>Total Investable Capital</ChartText>
          </TotalContainer> */}
          </PanelContent>
        </>
      </PanelWrapper>
    </HalfPanelContainer>
  );
};

const FooterText = styled(AppText)`
  font-size: 11px;
  opacity: 0.6;
  margin-top: 80px;
`;

const ChartContainer = styled.div`
  display: block;
`;

const LegendItem = styled.div`
  display: inline-block;
  vertical-align: top;
  max-width: 50%;
`;

const LegendContainer = styled.div`
  display: block;
  ${LegendItem}+${LegendItem} {
    margin-left: 15px;
    }
`;

const InnerLegendItem = styled.div`
  display: table;
  width: 100%;
`;

interface Color {
  color: string;
}
const LegendColorItem = styled.div`
  display: table-cell;
  vertical-align: top;
  width: 10px;
  padding-top: 2px;
`;
const LegendColor = styled.div<Color>`
  height: 10px;
  width: 10px;
  border-radius: 50%;
  background-color: ${(props) => props.color};
`;

const LegendLabel = styled.div`
  display: table-cell;
  vertical-align: top;
  height: 32px;
  max-height: 32px;
  padding-left: 10px;
`;

const LegendText = styled(AppText)`
  font-size: 12px;
  line-height: 1.25;
  color: rgba(26, 26, 26, 0.8);
`;

//**Chart */

const StyledChart = styled.ul`
  list-style: none;
  margin-top: 30px;
  margin-bottom: 0;
  padding-left: 0;
`;

const ChartListItem = styled.li`
  display: table;
  table-layout: fixed;
  width: 100%;
  margin-bottom: 10px;
`;

const ChartItemLabel = styled.div`
  display: table-cell;
  vertical-align: middle;
  width: 90px;
  border-right: 2px solid #1a1a1a;
  padding-right: 11px;
`;

const ChartItemContent = styled.div`
  display: table-cell;
  vertical-align: middle;
  width: 100%;
  padding-right: 30px;
`;

const ChartStackedBar = styled.div`
  display: block;
  width: 100%;
`;

interface BarWidth {
  width: number;
}

const MainBar = styled.div<BarWidth>`
  position: relative;
  vertical-align: top;
  float: left;
  display: block;
  min-width: 25px;
  width: ${(props) => `${props.width * 100}%`};
`;

interface BarColor {
  color?: string;
}

const BarItem = styled.div`
  height: 60px;
  background-color: ${(props) => (props.color ? props.color : "transparent")};
  background: ${(props) =>
    props.color
      ? props.color
      : "repeating-linear-gradient(45deg, rgba(69,174,235,0.4), rgba(69,174,235,0.4) 5px, transparent 5px, transparent 10px);"};
`;

const BarValue = styled.div`
  position: absolute;
  top: 50%;
  left: 50%;
  -webkit-transform: translate(-50%, -50%);
  transform: translate(-50%, -50%);
`;

const BarValueText = styled(AppText)<BarColor>`
  font-size: 14px;
  color: ${(props) => (props.color ? props.color : "#fff")};
`;

const UnallocatedContainer = styled.div`
  position: relative;
  display: inline-block;
  vertical-align: middle;
  float: right;
  width: 60px;
  height: 60px;
  margin-right: -30px;
`;

const InnerUnallocatedContainer = styled.div`
  position: absolute;
  display: block;
  top: 0;
  right: 0;
  bottom: 0;
  left: 0;
  border-radius: 50%;
  background-color: #0c3658;
  color: #fff;
`;

const UnallocatedContainerContent = styled.div`
  display: table;
  height: 100%;
  width: 100%;
`;

const UnallocatedContainerInnerContent = styled.div`
  display: table-cell;
  vertical-align: middle;
`;

const CircleValueText = styled(AppText)<BarColor>`
  font-size: 14px;
  text-align: center;
  width: auto;
  color: ${(props) => (props.color ? props.color : "#fff")};
`;

const TotalContainer = styled.div`
  margin-top: 18px;
`;

const ChartText = styled(AppText)`
  font-size: 14px;
`;

const ChartBoldText = styled(AppText)`
  font-size: 36px;
  font-weight: 700;
`;

const CapitalDeployedByStateComponent = observer(CapitalDeployedByStatePanel);

export { CapitalDeployedByStateComponent };
